import classNames from 'classnames'
import { Icon, IconColor, IconName, IconType } from 'components/DataDisplay/Icon/Icon'
import { IconButtonLink } from 'components/IconButtonLink/IconButtonLink'
import { useTranslation } from 'hooks/useTranslation'
import { FC } from 'react'
import { Text } from 'components/Text/Text'
import { Button } from 'components/Button/Button'
import { Routes } from 'services/router/Routes'
import styles from './ContactUs.module.scss'
import { Form } from './components/Form/Form'

type ContactUsProps = {
  className?: string
  callNumber?: {
    title: string
    phoneNumber: string
  }
  whatsappNumber?: {
    title: string
    phoneNumber: string
  }
  customEmail?: string
}

export const ContactUs: FC<ContactUsProps> = ({ className, callNumber, whatsappNumber, customEmail }) => {
  const { t } = useTranslation()

  return (
    <div className={classNames(styles.sellYourHouseContainer, styles.containerFluid, className)}>
      <div className={`${styles.infoContainer} ${styles.container}`}>
        <div className={styles.info}>
          <Text type="h2" weight="bold" className={styles.title}>
            {t('home:contact-us.title')}
          </Text>

          <Text type="headline">{t('home:contact-us.subtitle')}</Text>

          <Button className={styles.contactButton} color="red" href={Routes.webinar()} external>
            {t('home:contact-us.action-button')}
          </Button>

          <div className={styles.contactLinksContainer}>
            {callNumber && (
              <a href={`tel://${callNumber.phoneNumber}`} className={styles.contactLink}>
                <Icon name={IconName.PHONE} type={IconType.LINE} color={IconColor.RED} size={54} />
                <Text type="h3" weight="bold" className={styles.contactLinkText}>
                  {callNumber.title}
                </Text>
              </a>
            )}
            {whatsappNumber && (
              <a href={`https://wa.me/${whatsappNumber.phoneNumber}`} className={styles.contactLink}>
                <Icon name={IconName.WHATSAPP} type={IconType.LINE} color={IconColor.RED} size={54} />
                <Text type="h3" weight="bold" className={styles.contactLinkText}>
                  {whatsappNumber.title}
                </Text>
              </a>
            )}
            <a href={`mailto:${customEmail || 'ayuda@libeen.com'}`} className={styles.contactLink}>
              <Icon name={IconName.MAIL} type={IconType.LINE} color={IconColor.RED} size={54} />
              <Text type="h3" weight="bold" className={styles.contactLinkText}>
                {customEmail || 'ayuda@libeen.com'}
              </Text>
            </a>
          </div>

          <div className={styles.contactSocialLinksContainer}>
            <IconButtonLink href="https://www.facebook.com/Libeen.smarthousing" iconName={IconName.FACEBOOK} newTab />
            <IconButtonLink
              href="https://www.instagram.com/libeen.smarthousing/"
              iconName={IconName.INSTAGRAM}
              iconType={IconType.LINE}
              newTab
            />
            <IconButtonLink href="https://www.linkedin.com/company/libeen/" iconName={IconName.LINKEDIN} newTab />
            <IconButtonLink
              href="https://www.youtube.com/channel/UCFyMcdL7NlcEt__eEZ8KMTQ"
              iconName={IconName.YOUTUBE}
              iconType={IconType.LINE}
              newTab
            />
            <IconButtonLink href="https://www.tiktok.com/@libeensmarthousing" iconName={IconName.TIK_TOK} iconType={IconType.FILL} newTab />
          </div>
        </div>

        <div className={styles.formContainer}>
          <Form />
        </div>
      </div>
    </div>
  )
}
