import { Api } from 'types/Api'
import { useMutation, UseMutationOptions } from 'react-query'
import { ContactFormFields } from './useContactForm'

export namespace PostSendContactForm {
  export type Response = any
  export type Variables = ContactFormFields
  export type Error = Api.Error
  export type Options = UseMutationOptions<PostSendContactForm.Response, PostSendContactForm.Error, PostSendContactForm.Variables>
}

export const useSendContactForm = (options?: PostSendContactForm.Options) => {
  const mutationFn = (data: PostSendContactForm.Variables) =>
    fetch(`${window.location.protocol}//${window.location.host}/api/contact/lead`, {
      method: 'POST',
      body: JSON.stringify(data),
    }).then((res) => res.json())

  const { mutate, ...rest } = useMutation<PostSendContactForm.Response, PostSendContactForm.Error, PostSendContactForm.Variables>(
    mutationFn,
    options
  )

  return {
    sendContactForm: mutate,
    ...rest,
  }
}
