import { useFormik, FormikConfig } from 'formik'
import { useTranslation } from 'hooks/useTranslation'
import * as yup from 'yup'

export type ContactFormFields = {
  name: string
  phonePrefix: string
  phone: string
  email: string
  enquiry?: string
  legalTerms: boolean
  newsletter: boolean
}

export const INITIAL_VALUES: ContactFormFields = {
  name: '',
  phonePrefix: '34',
  phone: '',
  email: '',
  enquiry: undefined,
  legalTerms: false,
  newsletter: false,
}

type UseContactFormProps = {
  onSubmit: FormikConfig<ContactFormFields>['onSubmit']
  validate?: boolean
}

export type ContactFormValues = ReturnType<typeof useContactForm>

export const useContactForm = ({ onSubmit, validate = true }: UseContactFormProps) => {
  const { t } = useTranslation()

  const schema = yup.object({
    name: yup.string().required(t('common:required')),
    phonePrefix: yup.string().required(t('common:required')),
    phone: yup.string().required(t('common:required')),
    email: yup.string().email(t('common:invalid')).required(t('common:required')),
    enquiry: yup.string().required(t('common:required')),
    privacyPolicy: yup.boolean().oneOf([true], t('common:required')),
  })

  return useFormik<ContactFormFields>({
    validationSchema: validate ? schema : undefined,
    initialValues: INITIAL_VALUES,
    onSubmit,
  })
}
