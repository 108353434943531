import { IconName, IconType } from 'components/DataDisplay/Icon/Icon'
import { ErrorInputMessage } from 'components/Inputs/ErrorInputMessage/ErrorInputMessage'
import { TextField } from 'components/Inputs/TextField/TextField'
import { Select, SelectOptionModel } from 'components/Select/Select'
import { useField, useFormikContext } from 'formik'
import React, { useEffect } from 'react'
import styles from './InputPhone.module.scss'
import { PhoneCodeList } from './phoneList'

const optionsPhoneCodeList: SelectOptionModel[] = PhoneCodeList.map((phoneCode) => ({
  value: phoneCode.toString(),
  title: `+${phoneCode}`,
}))

interface InputPhoneProps {
  name: string
  placeholder?: string
  defaultPrefix?: string
  label?: string
}

export const InputPhone = ({ name = 'telephone', defaultPrefix = '34', label, placeholder }: InputPhoneProps) => {
  const { setFieldValue, values } = useFormikContext<Record<string, any>>()
  const [input, meta, helpers] = useField({
    name,
  })

  const onPrefix = (val: string) => {
    setFieldValue(`${name}Prefix`, val)
  }

  const onNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    if (value && !value.match(/^[0-9]+$/)) {
      e.preventDefault()
      return
    }
    helpers.setValue(value)
  }

  // when `defaultPrefix` is received then set it.
  useEffect(() => {
    setFieldValue(`${name}Prefix`, defaultPrefix)
    // setFieldValue is not wrapped in useCallback
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultPrefix])

  const error = meta.touched && meta.error ? meta.error : ''
  const hasError = !!error

  return (
    <div className={styles.phone}>
      <div className={styles.inputs}>
        <Select
          id={`${name}Prefix`}
          label={label}
          options={optionsPhoneCodeList}
          onChange={onPrefix}
          value={values ? values[`${name}Prefix`] : 34}
          hasError={hasError}
          shrink
        />
        <TextField
          id="telephone"
          name={name}
          type="tel"
          rightIconName={IconName.PHONE}
          iconType={IconType.LINE}
          placeholder={placeholder}
          onChange={onNumber}
          onBlur={input.onBlur}
          value={input.value}
          hasError={hasError}
        />
      </div>
      <ErrorInputMessage text={error} htmlFor="telephone" className={styles.errorMessage} />
    </div>
  )
}
