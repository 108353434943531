import React from 'react'
import { FormTextField } from 'components/Inputs/TextField/TextField'
import { FormikProvider } from 'formik'
import { InputPhone } from 'components/InputPhone/InputPhone'
import { FormCheckboxLegalPrivacy } from 'components-presets/CheckboxLegalPrivacy'
import { Button } from 'components/Button/Button'
import { Select } from 'components/Select/Select'
import { Checkbox } from 'components/Inputs/Checkbox/Checkbox'
import { useTranslation } from 'hooks/useTranslation'
import { INITIAL_VALUES, useContactForm } from './useContactForm'
import styles from './Form.module.scss'
import { useSendContactForm } from './useSendContactForm'
import { ENQUIRY_OPTIONS } from './constants'

export const Form = () => {
  const { t } = useTranslation()

  const { sendContactForm } = useSendContactForm()

  const form = useContactForm({
    onSubmit: (values) => {
      sendContactForm(values, {
        onSettled: () => {
          form.resetForm({
            values: INITIAL_VALUES,
          })
        },
      })
    },
  })

  const enquiryOptions = [
    {
      title: t('home:contact-us.form.fields.enquiry.options.visited'),
      value: ENQUIRY_OPTIONS.VISITED,
    },
    {
      title: t('home:contact-us.form.fields.enquiry.options.not-visited'),
      value: ENQUIRY_OPTIONS.NOT_VISITED,
    },
    {
      title: t('home:contact-us.form.fields.enquiry.options.starting'),
      value: ENQUIRY_OPTIONS.STARTING,
    },
    {
      title: t('home:contact-us.form.fields.enquiry.options.info'),
      value: ENQUIRY_OPTIONS.INFO,
    },
  ]

  return (
    <FormikProvider value={form}>
      <form onSubmit={form.handleSubmit}>
        <div className={styles.fields}>
          <FormTextField
            id="name"
            name="name"
            label={t('home:contact-us.form.fields.name.label')}
            placeholder={t('home:contact-us.form.fields.name.placeholder')}
            type="text"
          />
          <FormTextField
            id="email"
            name="email"
            label={t('home:contact-us.form.fields.email.label')}
            placeholder={t('home:contact-us.form.fields.email.placeholder')}
            type="text"
          />
          <InputPhone
            name="phone"
            label={t('home:contact-us.form.fields.phone.label')}
            placeholder={t('home:contact-us.form.fields.phone.placeholder')}
          />
          <Select
            id="enquiry"
            label={t('home:contact-us.form.fields.enquiry.label')}
            placeholder={t('home:contact-us.form.fields.enquiry.placeholder')}
            options={enquiryOptions}
            onChange={(value) => form.setFieldValue('enquiry', value)}
            value={form.values.enquiry}
            hasError={Boolean(form.touched.enquiry && form.errors.enquiry)}
            error={form.touched.enquiry && form.errors.enquiry ? form.errors.enquiry : undefined}
          />
        </div>
        <div className={styles.checkboxes}>
          <FormCheckboxLegalPrivacy name="legalTerms" value="" size="small" />
          <Checkbox
            name="newsletter"
            label={t('home:contact-us.form.fields.newsletter.label')}
            value="newsletter"
            size="small"
            onClick={form.handleChange}
            checked={form.values.newsletter}
          />
        </div>
        <Button disabled={!form.values.legalTerms} type="submit" width="expand">
          {t('home:contact-us.form.submit-button')}
        </Button>
      </form>
    </FormikProvider>
  )
}
