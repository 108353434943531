/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
import { CheckboxProps, FormCheckbox } from 'components/Inputs/Checkbox/Checkbox'
import Trans from 'next-translate/Trans'
import styles from './CheckboxLegalPrivacy.module.scss'

export const FormCheckboxLegalPrivacy = (props: Omit<CheckboxProps, 'label' | 'children'>) => {
  return (
    <FormCheckbox {...props} label="">
      <Trans
        i18nKey="common:accept-terms-policy"
        components={[
          <a key="legal" href="/legal" target="_blank" rel="noopener noreferrer" className={styles.legalPrivacy} />,
          <a key="privacy" href="/privacy" target="_blank" rel="noopener noreferrer" className={styles.legalPrivacy} />,
        ]}
      />
    </FormCheckbox>
  )
}
