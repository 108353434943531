import classNames from 'classnames'
import { Icon, IconColor, IconName, IconType } from 'components/DataDisplay/Icon/Icon'
import React, { ComponentPropsWithRef, FC } from 'react'

import styles from './IconButtonLink.module.scss'

export enum IconButtonLinkDesign {
  RED,
  BLUE,
  GREY,
  BURGUNDY,
  GREEN,
  YELLOW,
}

export enum IconButtonLinkSize {
  BIG,
  SMALL,
  ICON,
}

type IconButtonLinkProps = {
  href: string
  design?: IconButtonLinkDesign
  fullWidth?: boolean
  autoWidth?: boolean
  iconName: IconName
  iconColor?: IconColor
  iconSize?: number
  iconType?: IconType
  newTab?: boolean
}

const iconButtonLinkDesignToStyle: { [key in IconButtonLinkDesign]: string } = {
  [IconButtonLinkDesign.RED]: styles.red,
  [IconButtonLinkDesign.BLUE]: styles.blue,
  [IconButtonLinkDesign.GREY]: styles.grey,
  [IconButtonLinkDesign.BURGUNDY]: styles.burgundy,
  [IconButtonLinkDesign.GREEN]: styles.green,
  [IconButtonLinkDesign.YELLOW]: styles.yellow,
}

export const IconButtonLink: FC<ComponentPropsWithRef<'a'> & IconButtonLinkProps> = ({
  design = IconButtonLinkDesign.BLUE,
  iconColor = IconColor.WHITE,
  fullWidth = false,
  iconSize = 27,
  iconType = IconType.FILL,
  iconName,
  className,
  newTab,
  ...props
}) => (
  <a
    target={newTab ? '_blank' : undefined}
    className={classNames(className, styles.iconButtonLink, iconButtonLinkDesignToStyle[design], fullWidth && styles.fullwidth)}
    {...props}
  >
    <Icon name={iconName} color={iconColor} size={iconSize} type={iconType} />
  </a>
)
