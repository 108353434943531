// All countries
// length 252
// generated with https://www.html-code-generator.com/javascript/json/country-names
export const PhoneCodeList = [
  34, 93, 358, 355, 213, 1684, 376, 244, 1264, 672, 1268, 54, 374, 297, 61, 43, 994, 1242, 973, 880, 1246, 375, 32, 501, 229, 1441, 975, 591,
  599, 387, 267, 55, 246, 673, 359, 226, 257, 855, 237, 1, 238, 1345, 236, 235, 56, 86, 57, 269, 242, 682, 506, 225, 385, 53, 357, 420, 45, 253,
  1767, 1809, 593, 20, 503, 240, 291, 372, 251, 500, 298, 679, 33, 594, 689, 262, 241, 220, 995, 49, 233, 350, 30, 299, 1473, 590, 1671, 502, 44,
  224, 245, 592, 509, 0, 39, 504, 852, 36, 354, 91, 62, 98, 964, 353, 972, 1876, 81, 962, 7, 254, 686, 850, 82, 381, 965, 996, 856, 371, 961,
  266, 231, 218, 423, 370, 352, 853, 389, 261, 265, 60, 960, 223, 356, 692, 596, 222, 230, 52, 691, 373, 377, 976, 382, 1664, 212, 258, 95, 264,
  674, 977, 31, 687, 64, 505, 227, 234, 683, 1670, 47, 968, 92, 680, 970, 507, 675, 595, 51, 63, 48, 351, 1787, 974, 40, 70, 250, 290, 1869,
  1758, 508, 1784, 684, 378, 239, 966, 221, 248, 232, 65, 421, 386, 677, 252, 27, 211, 94, 249, 597, 268, 46, 41, 963, 886, 992, 255, 66, 670,
  228, 690, 676, 1868, 216, 90, 7370, 1649, 688, 256, 380, 971, 598, 998, 678, 58, 84, 1284, 1340, 681, 967, 260, 263,
]
